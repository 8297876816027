
import { defineComponent, ref, computed, toRefs, onMounted } from 'vue';
import DealerClaimAPI from '@/API/claimDelay/dealerClaim';
import { getOfferType } from '@/API/claimDelay/common';
import fixTable from '@/hooks/checking/useFixTable';
import useDelay from '@/hooks/claimDelay/useDelay';
import { SelectFormat, ProgramType } from '@/views/Delay/type';
import downloadFile from '@/utils/claim/downloadFile';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export enum RequestTypeListEnum {
    '过期申请' = 1,
    '过期撤回',
}

export enum StatusListEnum {
    '已提交' = 1,
    '区域已通过',
    '区域已拒绝',
    'SIPO已通过',
    'SIPO已拒绝',
}

export default defineComponent({
    setup() {
        const router = useRouter();
        const store = useStore();
        const failReason = ref<string>('');
        const failReasonVisible = ref<boolean>(false);
        const requestTypeList = ref<SelectFormat[]>([
            { value: '1', title: '过期申请' },
            { value: '2', title: '过期撤回' },
        ]);
        const statusList = ref<SelectFormat[]>([
            { value: '1', title: '已提交' },
            { value: '2', title: '区域已通过' },
            { value: '3', title: '区域已拒绝' },
            { value: '4', title: 'SIPO已通过' },
            { value: '5', title: 'SIPO已拒绝' },
        ]);
        const programTypeList = ref<ProgramType[]>([]);
        const genProgramTypeList = () => {
            getOfferType().then((res: any) => {
                const offerTypeList = res.incentiveCategory.reduce(
                    (totalList: any, arr: any) => {
                        return [...totalList, ...arr.offerType];
                    },
                    []
                );
                programTypeList.value = offerTypeList;
            });
        };
        genProgramTypeList();

        const tableData = ref([]);
        const columns = [
            {
                title: '申请编号',
                width: 150,
                dataIndex: 'requestCode',
            },
            {
                title: '申请类别',
                width: 100,
                dataIndex: 'requestType',
                slots: { customRender: 'requestType' },
            },
            {
                title: '项目编号',
                width: 150,
                dataIndex: 'programCode',
            },
            {
                title: '项目名称',
                width: 200,
                dataIndex: 'programName',
            },
            {
                title: '项目类型',
                width: 150,
                dataIndex: 'programType',
                slots: { customRender: 'programType' },
            },
            {
                title: '项目区间',
                width: 200,
                dataIndex: 'eligiblePeriod',
            },
            {
                title: '申请日期',
                width: 120,
                dataIndex: 'requestDate',
            },
            {
                title: '车辆总计',
                width: 100,
                dataIndex: 'vinCount',
            },
            {
                title: '状态',
                width: 120,
                dataIndex: 'status',
                slots: { customRender: 'status' },
            },
            {
                title: '文件',
                width: 100,
                slots: { customRender: 'document' },
            },
            {
                title: '失败原因',
                width: 100,
                slots: { customRender: 'failReason' },
            },
        ];

        const mapRequestType = (text: string): string => {
            return RequestTypeListEnum[text];
        };
        const mapStatus = (text: string): string => {
            return StatusListEnum[text];
        };
        //* 限定表格
        const height: number =
            50 + 50 + 8 + 80 + 8 + 133 + 10 + 8 + 10 + 48 + 135;
        const tableHeight = fixTable(height);

        //? 数据初始化
        const {
            overviewValue,
            spaceSize,
            requestCode,
            requestType,
            requestDate,
            status,
            programCode,
            programName,
            programType,
            pagination,
        } = useDelay();

        const failReasonClass = (record: any) => {
            const status = record.status;
            const activeStatusList = ['3', '5'];
            //? ['区域已拒绝', 'SIPO已拒绝']
            if (activeStatusList.includes(status)) {
                return 'table-btn';
            }
            return 'table-btn-nonactive';
        };

        const tabChange = () => {
            router.push({ path: '/delayCallback/dealerDelayApply' });
        };

        //TODO 概览数据
        const dealerCode = computed(() => {
            return (store.state.user as any).organization.entityCode;
        });

        const getOverviewValue = () => {
            const params = {
                dealerCode: dealerCode.value,
                regionalManagerCode: '',
            };
            DealerClaimAPI.getOverviewValue(params).then((res) => {
                overviewValue.delayRequset = res.delayNum;
                overviewValue.delayRequsetApproved = res.delayPendingNum;
                overviewValue.callBackRequset = res.callbackNum;
                overviewValue.callBackRequsetApproved = res.callbackPendingNum;
            });
        };

        const reset = () => {
            requestCode.value = '';
            requestType.value = null;
            requestDate.value = undefined;
            status.value = null;
            programCode.value = '';
            programName.value = '';
            programType.value = null;
        };

        const getTableData = () => {
            const params = {
                requestCode: requestCode.value,
                requestType: requestType.value || '',
                requestDate:
                    (requestDate.value?.format('YYYY/MM/DD') as string) || '',
                status: status.value || '',
                programCode: programCode.value,
                programName: programName.value,
                programType: programType.value || '',
                dealerCode: dealerCode.value,
                current: pagination.currentPage - 1,
                size: pagination.pageSize,
            };
            DealerClaimAPI.searchData(params).then((res) => {
                tableData.value = res.data;
                pagination.total = parseInt(res.totalNum);
            });
        };

        const search = () => {
            pagination.currentPage = 1;
            getTableData();
        };

        const quickSearch = (requestTypeCondition: string, statusCondition: null | string) => {
            reset();
            requestType.value = requestTypeCondition;
            status.value = statusCondition;
            search();
        };

        const exportExcel = () => {
            const params = {
                url: '/claimapi/delay/apply/export',
                method: 'post',
                params: {
                    requestCode: requestCode.value,
                    requestType: requestType.value || '',
                    requestDate:
                        (requestDate.value?.format('YYYY/MM/DD') as string) ||
                        '',
                    status: status.value || '',
                    programCode: programCode.value,
                    programName: programName.value,
                    programType: programType.value || '',
                    dealerCode: dealerCode.value,
                },
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
        };

        const submit = () => {
            router.push({
                name: 'DelayDealerList',
            });
        };

        const pageChange = (page: number) => {
            pagination.currentPage = page;
            getTableData();
        };
        const sizeChange = (page: number, pageSize: number) => {
            pagination.pageSize = pageSize;
            pagination.currentPage = page;
            getTableData();
        };

        const downLoad = (href: string) => {
            const downloadElement = document.createElement('a');
            downloadElement.href = href;
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
        };

        const getDocument = (record: any) => {
            const params = {
                applyInfoId: record.id,
            };
            DealerClaimAPI.getDocument({ params }).then((res) => {
                downLoad(res);
            });
        };

        const openModal = (record: any) => {
            failReason.value = record.refuseReason;
            failReasonVisible.value = true;
        };

        const closeModal = () => {
            failReasonVisible.value = false;
        };

        onMounted(() => {
            getOverviewValue();
            search();
        });
        return {
            failReason,
            failReasonVisible,
            tableHeight,
            spaceSize,
            requestCode,
            requestType,
            requestTypeList,
            requestDate,
            status,
            statusList,
            programCode,
            programName,
            programType,
            programTypeList,
            tableData,
            columns,
            reset,
            search,
            ...toRefs(overviewValue),
            ...toRefs(pagination),
            pageChange,
            sizeChange,
            failReasonClass,
            exportExcel,
            submit,
            mapRequestType,
            mapStatus,
            getDocument,
            openModal,
            closeModal,
            tabChange,
            quickSearch
        };
    },
});
